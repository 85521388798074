import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/fbert/OneDrive/Documents/wwwBertramFamilyCom/GatsbyJS/src/components/layout-mdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`We're sorry...`}</h2>
    <p>{`... the page you are looking for does not exist.`}</p>
    <p>{`Start looking for it at the `}<a parentName="p" {...{
        "href": "/"
      }}>{`family overview`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      